<template>

<div class="delete" :class="{'is-loading': isLoading}">

	<app-icon icon="warning" class="delete-icon" />

	<div class="delete-title">Delete {{ noun }}</div>

	<div class="delete-text">Are you sure you wish to delete the <b v-if="isMany">{{ isMany }} selected </b>{{ noun }}? This cannot be reversed.</div>

	<div class="delete-buttons">

		<div class="delete-buttons-item is-danger" v-on:click="onConfirmClick">
			<template v-if="!isLoading">Confirm</template>
			<app-icon icon="loading" v-if="isLoading" />
		</div>

		<div class="delete-buttons-item" v-on:click="onCancelClick">Cancel</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			isLoading: false
		}

	},

	computed: {

		noun: function() {

			return (this.isMany) ? this.$route.meta.plural : this.$route.meta.noun

		},

		isMany: function() {

			return (this.$route.params.id.indexOf(',') === -1) ? false : this.$route.params.id.split(',').length

		},

		title: function() {

			return 'title'

		},

		backPath: function() {

			if (this.$route.query.redirect === 'slot') {

				return {
					name: 'Convention.Volunteers.Slots.Edit',
					params: {
						id: this.$route.query.slot,
						role: this.$route.query.role,
					}
				}

			} else if (this.$route.query.redirect === 'badge') {

				return {
					name: 'Convention.Volunteers.Badges.Edit',
					params: {
						id: this.$route.query.badge
					}
				}

			} else {

				return {
					name: this.$route.meta.complete
				}

			}

		}

	},

	methods: {

		onConfirmClick: async function() {
			
			this.isLoading = true

			if (this.isMany) {

				await this.$api.delete([this.$endpoint(this.$route.meta.apiMultiple), this.$route.params.id])

				this.$notify({
					message: ['You have successfully deleted the<b>', this.isMany, 'selected</b>', this.noun, '.']
				})

			} else {

				await this.$api.delete([this.$endpoint(this.$route.meta.api), this.$route.params.id])

				this.$notify({
					message: ['You have successfully deleted the', this.noun, '<b>', this.title, '</b>.']
				})

			}

			this.$router.push(this.backPath)
			
		},

		onCancelClick: function() {

			if (this.$route.query.redirect) {

				this.$router.push(this.backPath)

			} else {

				if (this.isMany || this.$route.meta.noedit) {

					this.$router.push({
						name: this.$route.name.replace('.Delete', '')
					})

				} else {

					this.$router.push({
						name: this.$route.name.replace('.Delete', '.Edit'),
						params: {
							id: this.$route.params.id
						}
					})

				}

			}

		}

	}

}

</script>

<style scoped>

.delete {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #f25151;
}

.delete.is-loading {
	pointer-events: none;
}

.delete-icon {
	font-size: 96px;
}

.delete-title {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

.delete-text {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 100px;
	text-align: center;
	color: #333;
	font-weight: 300;
}

.delete-buttons {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.delete-buttons-item {
	width: 150px;
	height: 40px;
	border: 2px solid #ccc;
	line-height: 36px;
	text-align: center;
	border-radius: 16px;
	color: #333;
	margin: 0px 10px;
	font-size: 16px;
	cursor: pointer;
}

.delete-buttons-item.is-danger {
	color: #fff;
	font-weight: 400;
	border-color: #f25151;
	background-color: #f25151;
}

</style>
